import { useContext } from 'react';
import { useRouter } from 'next/router';
import { css, styled } from '@nx-kit/styling';
import { HStack, Spacer } from '@nx-kit/layout';
import { useHover } from '@react-aria/interactions';
import useTranslation from 'next-translate/useTranslation';
import useIsSticky from 'hooks/useIsSticky';
import { NavLevel1 } from 'components/organisms/Header/Header.types';
import { IconType } from 'components/atoms/Icon/iconMap';
import Logo from 'components/atoms/Logo/Logo';
import Link from 'components/atoms/Link/Link';
import Level1 from './Level1';
import TagManager from 'react-gtm-module';
import { HeaderContext } from 'contexts/header/HeaderContext';
import { DomainContext } from 'contexts/domain/DomainContext';

type MainNavProps = {
  slices: Array<NavLevel1>;
};

const Wrapper = styled.div<{ sticky: boolean }>`
  width: 100%;
  z-index: ${({ theme }) => theme.global.zIndex.header};
  top: 34px;
  ${({ sticky }) =>
    sticky
      ? css`
          position: fixed;
          left: 0;
        `
      : css`
          position: absolute;
        `}
`;

const NavSpacer = styled.div`
  height: 110px;
`;

const Nav = styled.nav`
  height: 100%;

  a {
    hyphens: auto;
  }
`;

const Ul = styled(HStack)`
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
`;

const Li = styled.li`
  height: 100%;
`;

const MainNavBar = styled(HStack)<{ sticky: boolean; open: boolean }>`
  background-color: ${({ theme }) => theme.global.color.white};
  color: ${({ theme }) => theme.global.color.gray900};
  height: 110px;
  padding: 0 60px;
  transition: height 200ms;

  ${({ sticky }) =>
    sticky &&
    css`
      height: 100px;
      background-color: ${({ theme }) => theme.global.color.white};
    `}

  ${({ open }) =>
    open &&
    css`
      border-bottom-color: ${({ theme }) => theme.global.color.gray100};
    `}
`;

const StyledLogo = styled(Logo)`
  width: 140px;
  height: auto;
`;

const Backdrop = styled.div<{ open: boolean; sticky: boolean }>`
  position: absolute;
  z-index: ${({ theme }) => theme.global.zIndex.minusOne};
  bottom: 0;
  left: 0;
  width: 100%;
  height: 510px;
  background-color: transparent;

  transition: transform 400ms, background-color 200ms 400ms;
  ${({ open, sticky }) =>
    (open || sticky) &&
    css`
      transition: transform 400ms, background-color 0s 0s;
      background-color: ${({ theme }) => theme.global.color.white};
      box-shadow: 0 3px 12px rgba(0, 0, 0, 0.12);
    `}
  ${({ open }) =>
    open &&
    css`
      transform: translateY(400px);
    `}
`;

const MainNav = ({ slices }: MainNavProps) => {
  const { t } = useTranslation('common');
  const { locale } = useRouter();
  const { isSticky, isStickyProps } = useIsSticky();
  const { open, setOpen } = useContext(HeaderContext);
  const { hoverProps } = useHover({
    onHoverEnd: () => {
      setOpen(-1);
    },
  });

  const donateLink = {
    url: t('links.donateNow.url'),
    link_type: 'Web',
    label: t('links.donateNow.label'),
  };

  const {
    computed: { isCourse },
  } = useContext(DomainContext);

  return (
    <>
      <Wrapper sticky={isSticky}>
        <Backdrop open={open >= 0} sticky={isSticky} />
        <MainNavBar sticky={isSticky} open={open >= 0}>
          <Link url={{ link_type: 'Web', url: `/${locale}` }}>
            <StyledLogo />
          </Link>

          <Spacer />
          <Nav {...hoverProps}>
            <Ul elementType="ul">
              {slices.map((slice, index) => (
                /* eslint-disable-next-line react/no-array-index-key */
                <Li key={`level1-${slice.primary.link.url}-${index}`}>
                  <Level1 {...slice} index={index} />
                </Li>
              ))}
            </Ul>
          </Nav>
          <Spacer />
          {!isCourse && (
            <Link
              onPress={() => {
                TagManager.dataLayer({
                  dataLayer: {
                    event: 'Donation Made',
                    action: 'donationMade',
                    label: 'donationAmount',
                    category: 'donationMade',
                  },
                });
              }}
              icon={'DONATE' as IconType}
              skin="primary"
              text={donateLink.label}
              url={donateLink}
            />
          )}
        </MainNavBar>
      </Wrapper>
      <NavSpacer {...isStickyProps} />
    </>
  );
};

export default MainNav;
